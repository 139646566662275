function usePubSub() {
    console.log('using pubsub');
    const _subscribers = {};

    return {
        subscribe: function (event, fn) {
            console.log('subscribe@' + event);
            if (Array.isArray(_subscribers[event])) {
                _subscribers[event] = [..._subscribers[event], fn];
            } else {
                _subscribers[event] = [fn];
            }
            return () => {
                this.unsubscribe(event, fn);
            };
        },
        unsubscribe: function (event, fn) {
            console.log('unsubscribe@' + event);
            _subscribers[event] = _subscribers[event].filter(
                (sub) => sub !== fn
            );
        },
        publish: function (event, data) {
            console.log('publish@' + event);
            if (Array.isArray(_subscribers[event])) {
                _subscribers[event].forEach((sub) => {
                    sub(data);
                });
            }
            return false;
        }
    }
}